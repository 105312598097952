import React from 'react'
import { Collapse } from 'antd'
import { Link } from 'gatsby'
import cookies from 'react-cookies'
const FaqEmployerComponent = () => {
  let user = cookies.load('user')
  const { Panel } = Collapse
  return (
    <>
      <div className="container mx-auto py-9 lg:py-16">
        <div className="px-[30px] w-full lg:w-4/5 mx-auto">
          <h1 className="font-playfair text-headingThree lg:text-headingOne text-merlot">
            Frequently Asked Questions
          </h1>
          <div className="bg-white shadow-profileCard rounded-lg mt-5 lg:mt-8">
            <Collapse
              className="w-full compare-data-collapse faq-collapse"
              defaultActiveKey={['1']}
              expandIconPosition="right"
              expandIcon={({ isActive }) => {
                if (isActive) {
                  return <img src="/icons/icon-collapse-minus.svg" />
                } else {
                  return <img src="/icons/icon-collapse-plus.svg" />
                }
              }}
            >
              <Panel header="What is MedReps.com?" key="1">
                <div className="px-8">
                  <div className="mb-0">
                    <p className="font-sans text-sm">
                      MedReps.com is a paid for, exclusive job site for medical
                      sales. Our members are experienced medical sales
                      professionals.
                    </p>
                  </div>
                </div>
              </Panel>
            </Collapse>
          </div>
          <div className="bg-white shadow-profileCard rounded-lg mt-5 lg:mt-8">
            <Collapse
              className="w-full compare-data-collapse faq-collapse"
              defaultActiveKey={['1']}
              expandIconPosition="right"
              expandIcon={({ isActive }) => {
                if (isActive) {
                  return <img src="/icons/icon-collapse-minus.svg" />
                } else {
                  return <img src="/icons/icon-collapse-plus.svg" />
                }
              }}
            >
              <Panel header="Why should I post jobs on MedReps?" key="1">
                <div className="px-8">
                  <div className="mb-0">
                    <p className="font-sans text-sm">
                      When posting jobs on MedReps, you can be confident that
                      you will receive relevant applicants. Users on MedReps are
                      required to pay a small membership fee. As such, MedReps
                      applicants are a targeted, filtered pool of candidates.
                    </p>
                  </div>
                </div>
              </Panel>
            </Collapse>
          </div>
          <div className="bg-white shadow-profileCard rounded-lg mt-5 lg:mt-8">
            <Collapse
              className="w-full compare-data-collapse faq-collapse"
              defaultActiveKey={['1']}
              expandIconPosition="right"
              expandIcon={({ isActive }) => {
                if (isActive) {
                  return <img src="/icons/icon-collapse-minus.svg" />
                } else {
                  return <img src="/icons/icon-collapse-plus.svg" />
                }
              }}
            >
              <Panel header="Educated" key="1">
                <div className="px-8">
                  <div className="mb-0">
                    <ul className="ml-0 font-sans text-sm faq-icon-list">
                      <li className="flex mb-6">
                        <div className="mr-4">
                          <img src="/icons/icon-check-filled.svg" />
                        </div>
                        73% hold a Bachelor’s degree
                      </li>
                      <li className="flex mb-6">
                        <div className="mr-4">
                          <img src="/icons/icon-check-filled.svg" />
                        </div>
                        20% hold a Master’s/MBA
                      </li>
                    </ul>
                  </div>
                </div>
              </Panel>
            </Collapse>
          </div>
          <div className="bg-white shadow-profileCard rounded-lg mt-5 lg:mt-8">
            <Collapse
              className="w-full compare-data-collapse faq-collapse"
              defaultActiveKey={['1']}
              expandIconPosition="right"
              expandIcon={({ isActive }) => {
                if (isActive) {
                  return <img src="/icons/icon-collapse-minus.svg" />
                } else {
                  return <img src="/icons/icon-collapse-plus.svg" />
                }
              }}
            >
              <Panel header="Experienced" key="1">
                <div className="px-8">
                  <div className="mb-0">
                    <ul className="ml-0 font-sans text-sm faq-icon-list">
                      <li className="flex mb-6">
                        <div className="mr-4">
                          <img src="/icons/icon-check-filled.svg" />
                        </div>
                        96% have sales experience
                      </li>
                      <li className="flex mb-6">
                        <div className="mr-4">
                          <img src="/icons/icon-check-filled.svg" />
                        </div>
                        87% have medical / pharma sales experience
                      </li>
                    </ul>
                  </div>
                </div>
              </Panel>
            </Collapse>
          </div>
          <div className="bg-white shadow-profileCard rounded-lg mt-5 lg:mt-8">
            <Collapse
              className="w-full compare-data-collapse faq-collapse"
              defaultActiveKey={['1']}
              expandIconPosition="right"
              expandIcon={({ isActive }) => {
                if (isActive) {
                  return <img src="/icons/icon-collapse-minus.svg" />
                } else {
                  return <img src="/icons/icon-collapse-plus.svg" />
                }
              }}
            >
              <Panel header="Employable!" key="1">
                <div className="px-8">
                  <div className="mb-0">
                    <ul className="ml-0 font-sans text-sm faq-icon-list">
                      <li className="flex mb-6">
                        <div className="mr-4">
                          <img src="/icons/icon-check-filled.svg" />
                        </div>
                        73% are employed full-time
                      </li>
                      <li className="flex mb-6">
                        <div className="mr-4">
                          <img src="/icons/icon-check-filled.svg" />
                        </div>
                        71% are employed in medical / pharma sales
                      </li>
                    </ul>
                  </div>
                </div>
              </Panel>
            </Collapse>
          </div>
          <div className="bg-white shadow-profileCard rounded-lg mt-5 lg:mt-8">
            <Collapse
              className="w-full compare-data-collapse faq-collapse"
              defaultActiveKey={['1']}
              expandIconPosition="right"
              expandIcon={({ isActive }) => {
                if (isActive) {
                  return <img src="/icons/icon-collapse-minus.svg" />
                } else {
                  return <img src="/icons/icon-collapse-plus.svg" />
                }
              }}
            >
              <Panel header="Does MedReps have a resume database?" key="1">
                <div className="px-8">
                  <div className="mb-0">
                    <p className="font-sans text-sm">
                      Yes! Unlimited job posting customer can purchase access to
                      our resume database. Search by job title, past employers,
                      state or zip code/radius, years of experience, and of
                      course, keywords to precisely identify your top
                      candidates. You will need to purchase a job posting
                      package before you can add the Resume Search feature.
                    </p>
                  </div>
                </div>
              </Panel>
            </Collapse>
          </div>
          <div className="bg-white shadow-profileCard rounded-lg mt-5 lg:mt-8">
            <Collapse
              className="w-full compare-data-collapse faq-collapse"
              defaultActiveKey={['1']}
              expandIconPosition="right"
              expandIcon={({ isActive }) => {
                if (isActive) {
                  return <img src="/icons/icon-collapse-minus.svg" />
                } else {
                  return <img src="/icons/icon-collapse-plus.svg" />
                }
              }}
            >
              <Panel header="How much does a job posting package cost?" key="1">
                <div className="px-8">
                  <div className="mb-0">
                    <p className="font-sans text-sm">
                      Prices vary depending on how many jobs you need to post
                      and how long you want to post them. View our job posting
                      options{' '}
                      <span className="font-semibold">
                        {' '}
                        <Link
                          className={`text-black`}
                          to={'/hiring-with-medReps/'}
                        >
                          here.
                        </Link>
                      </span>
                    </p>
                  </div>
                </div>
              </Panel>
            </Collapse>
          </div>
        </div>
      </div>
    </>
  )
}
export default FaqEmployerComponent
