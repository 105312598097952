import React from 'react'
import FaqComponent from '../components/pageComponents/faqEmployerComponent'
import Wrapper from '../components/wrapper'
const FaqEmployerpage = ({location}) => {
  return (
    <Wrapper location={location} title="FAQ Employer | MedReps"
      description="Get answers to all your frequently asked questions (FAQs) on a wide range of MedReps topics. From general inquiries about our products or services to specific queries about pricing, features and customer support, find comprehensive answers and solutions right here.">
      <FaqComponent />
    </Wrapper>
  )
}
export default FaqEmployerpage
